import React from 'react';
import { Col, Row, Image, Container, Button, Stack } from 'react-bootstrap';
import { BsFileEarmarkPdf, BsGlobe2, BsGithub } from 'react-icons/bs';
import './style.css';
import { trackButtonClick } from '../../helper';

interface SelectedWorkCardProps {
  title: string;
  description: any;
  image: any;
  gitHubLink?: string;
  websiteLink?: string;
  pdfLink?: string;
}

const SelectedWorkCard: React.FC<SelectedWorkCardProps> = ({
  title,
  description,
  image,
  gitHubLink,
  websiteLink,
  pdfLink,
}) => (
  <Container fluid>
    <Row className="mt-5">
      <Col sm={12} md={6}>
        <figure className="selected-work-img-figure">
          <Image src={image} className="selected-work-img" />
        </figure>
      </Col>
      <Col sm={12} md={6}>
        <h3 className="selected-work-title"> {title}</h3>
        <p className="selected-work-description">{description}</p>
        <Stack className="selected-work-icons" direction="horizontal" gap={4}>
          {gitHubLink && (
            <Button
              variant="outline-dark"
              role="link"
              href={gitHubLink}
              onClick={() => trackButtonClick(`work_${gitHubLink}_git`)}>
              <BsGithub size="1.2em" />
            </Button>
          )}
          {websiteLink && (
            <Button
              variant="outline-dark"
              role="link"
              href={websiteLink}
              onClick={() => trackButtonClick(`work_${websiteLink}_website`)}>
              <BsFileEarmarkPdf size="1.2em" />
            </Button>
          )}
          {pdfLink && (
            <Button
              variant="outline-dark"
              role="link"
              href={pdfLink}
              onClick={() => trackButtonClick(`work_${pdfLink}_pdf`)}>
              <BsGlobe2 size="1.2em" />
            </Button>
          )}
        </Stack>
      </Col>
    </Row>
  </Container>
);

export default SelectedWorkCard;
