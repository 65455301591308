import React, { useRef, useState } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import emailJs from '@emailjs/browser';

import { trackButtonClick } from '../../helper';
import './style.css';

const Contact = () => {
  const form: any = useRef();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState<null | string>(null);

  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const strippedName = String(name.replace(/\s/g, ''));

    if (email.length < 8 || !email.includes('@') || !email.includes('.')) {
      trackButtonClick('contact_invalid-email');
      setAlert('Please enter a valid email address.');
    } else if (message.length < 10) {
      trackButtonClick('contact_invalid-message');
      setAlert('Please enter a message with at least 10 characters.');
    } else if (name.length < 4 || !strippedName.match('^[a-zA-Z0-9]*$')) {
      trackButtonClick('contact_invalid-name');
      setAlert('Please enter a valid name.');
    } else {
      emailJs
        .sendForm('service_yu29mai', 'template_h0y7c7m', form.current, 'ngf7Plr2OTBM8BAme')
        .finally(() => {
          trackButtonClick('contact_email-sent');
          setEmailSent(true);
          setAlert(null);
        });
    }
  };

  return (
    <Container className="contact-form-container">
      {alert && <Alert variant="danger">{alert}</Alert>}
      {emailSent ? (
        <Row className="text-center">
          <h1> Message Received! </h1>
          <p> I will be in touch shortly.</p>
        </Row>
      ) : (
        <>
          <h2 className="fw-bold text-center"> Contact </h2>
          <h5 className="mt-2 text-center">
            I am always open to new opportunities and collaborations.
          </h5>
          <form className="mt-4" ref={form} onSubmit={sendEmail}>
            <Row>
              <Col sm={12} md={6}>
                <div className="form-group">
                  <h5> Name </h5>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className="form-group mt-3 mt-md-0">
                  <h5> Email </h5>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <div className="form-group">
                <h5> Additional detail </h5>
                <textarea
                  value={message}
                  name="message"
                  className="form-control"
                  onChange={(e) => setMessage(e.target.value)}
                  rows={3}
                />
              </div>
            </Row>
            <Row className="text-center justify-content-center">
              <Button className="mt-4 p-2 submit-btn" variant="primary" type="submit">
                Submit
              </Button>
            </Row>
          </form>
        </>
      )}
    </Container>
  );
};

export default Contact;
