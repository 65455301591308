import React from 'react';
import { Col, Container, Nav, Row, Stack } from 'react-bootstrap';
import nameLogo from '../../images/name-logo.png';
import { trackButtonClick } from '../../helper';

const Header = () => (
  <Container className="mt-3">
    <Row>
      <Col>
        <img src={nameLogo} style={{ height: '50px', width: '50px' }} alt="initials-logo" />
      </Col>

      <Col className="mt-3">
        <Stack className="float-end text-nowrap gap-4 gap-md-5" direction="horizontal">
          <Nav.Link href="#about" onClick={() => trackButtonClick('header_about')}>
            {' '}
            About{' '}
          </Nav.Link>
          <Nav.Link href="#selected-work" onClick={() => trackButtonClick('header_work')}>
            {' '}
            Work{' '}
          </Nav.Link>
          <Nav.Link href="#contact" onClick={() => trackButtonClick('header_contact')}>
            {' '}
            Contact{' '}
          </Nav.Link>
        </Stack>
      </Col>
    </Row>
  </Container>
);

export default Header;
